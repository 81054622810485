import React from 'react';
import { connect } from '../../Store';
import './cars.scss';
import Search from '../search/Search';

String.prototype.replaceAt = function(index, replacement) {
    return this.substr(0, index) + replacement + this.substr(index + replacement.length);
}

class Cars extends React.Component {

    countries = {
        'de': 'Germany',
        'fr': 'France',
        'gdr': 'German Democratic Republic',
        'hr': 'Croatia',
        'it': 'Italy',
        'jp': 'Japan',
        'kr': 'South Korea',
        'se': 'Sweden',
        'uk': 'United Kingdom',
        'us': 'United States of America',
    }

    state = {
        brands: [],
        search: '',
        selected: {wiki: ''},
    }

    constructor() {
        super();
        this.search = this.search.bind(this);
        this.getCarInfo = this.getCarInfo.bind(this);

        fetch('./cars.json')
            .then(result => result.json())
            .then(json => this.setState({brands: json.brands}))
    }

    search(event) {
        this.setState({search: event.target.value});
    }

    setCarInfo(data) {
        // debugger;
    }

    getCarInfo(car) {
        const title = car.wiki;
        if (!title) return;
        this.setState({selected: car});
        window.fetchJsonP = function(url, options) {
            return new Promise(function(resolve, reject) {
                let script = document.createElement('script');
                let callbackName = 'cb' + String(Math.random()).slice(-6);
                url += ~url.indexOf('?') ? '&' : '?';
                url += 'callback=fetchJsonP.cbReg.' + callbackName;
                script.src = url;
                script.onload = function() {
                    delete window.fetchJsonP.cbReg[callbackName];
                    script.remove();
                };
                script.onerror = function() {
                    delete window.fetchJsonP.cbReg[callbackName];
                    script.remove();
                    reject(new Error("o_O"));
                };
                window.fetchJsonP.cbReg[callbackName] = resolve;
                document.body.appendChild(script);
            });
        };
        window.fetchJsonP.cbReg = {};

        window.fetchJsonP(`${ title.includes("https")?title:`https://en.wikipedia.org/w/api.php?action=mobileview&format=json&page=${title}`}&redirect=no&sections=0&prop=text&sectionprop=toclevel%7Clevel%7Cline%7Cnumber%7Cindex%7Cfromtitle%7Canchor`)
            .then(data => {
                function removeStyles(el) {
                    el.removeAttribute('style');

                    if(el.childNodes.length > 0) {
                        for(var child in el.childNodes) {
                            /* filter element nodes only */
                            if(el.childNodes[child].nodeType === 1)
                                removeStyles(el.childNodes[child]);
                        }
                    }
                }
                let text = data.mobileview.sections[0].text;
                let wrapper = document.createElement('div');
                wrapper.innerHTML = text;
                removeStyles(wrapper);
                let info = wrapper.querySelectorAll('.infobox')[0];
                let table = info.querySelectorAll('tbody')[0];
                let title = table.removeChild(table.children[0]);
                this.setState({
                    info: info.outerHTML, isInfoOpen: true,
                    infoTitle: title.querySelectorAll('th')[0].innerText,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        const { search } = this.state;
        let filteredCount = 0;
        const brands = this.state.brands.map(brand => {
            let b = {...brand};
            let filteredCars = b.cars.filter(car => {
                let nameResult = car.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                let yearResult = car.year && car.year.toLowerCase().indexOf(search.toLowerCase()) > -1;
                let engineResult = car["engine-layout"] && car["engine-layout"].toLowerCase().indexOf(search.toLowerCase()) > -1;
                return nameResult || yearResult || engineResult;
            });
            const hasBrandName = b.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
            const hasBrandCountry = b.country.indexOf(search.toLowerCase()) > -1 || this.countries[b.country].toLowerCase().indexOf(search.toLowerCase()) > -1;
            if (filteredCars.length === 0 && !hasBrandName && !hasBrandCountry ) {
                return null;
            } else if (hasBrandName || hasBrandCountry) {
                filteredCount += b.cars.length;
                return b;
            }
            b.cars = filteredCars;
            filteredCount += filteredCars.length;
            return b;
        });
        return (
            <div className={`brands ${this.state.isInfoOpen ? 'info-is-open' : ''}`}>
                <div className="brand-list-wrapper">
                    <div className="brand-list-left-side">
                        <Search
                            filteredCount={filteredCount}
                            onSearch={this.search}
                            brands={this.state.brands}
                        />
                        <div className="brand-list">
                            {
                                brands.map(brand => {
                                    if (!brand) {
                                        return null;
                                    }
                                    let decade = 0;
                                    return (
                                        <div key={brand.name} className="brand-group">
                                            <div className="brand-box">
                                                <img alt={`Logo of ${brand.name}`} src={`${process.env.PUBLIC_URL}/img/cars/${brand.name}.png`} />
                                            </div>

                                            <div className="brand-scroll-wrapper">
                                                <div className="brand-models">
                                            {
                                                brand.cars.map(car => {
                                                    let decadeString = "";
                                                    if (decade < Math.floor(car.year/10.0)) {
                                                        decade = Math.floor(car.year/10);
                                                        decadeString = `${`${car.year}`.replaceAt(3,"0")}s`;
                                                    }
                                                    return (
                                                        <div
                                                            key={`${brand.name} – ${car.name}`}
                                                            className={`car-box ${car.wiki ? 'clickable' : ''}`}
                                                            onClick={() => this.getCarInfo(car)}
                                                        >
                                                            <img alt={`Pictogram of ${brand.name} – ${car.name}`}src={`${process.env.PUBLIC_URL}/img/cars/${brand.name} – ${car.name}.png`} />
                                                            { decadeString !== "" &&
                                                                <div className="show-decade">{decadeString}</div>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="info-wrapper" >
                        <div
                            className="info-box"
                        >
                            <div className="info-title">
                                <a
                                    href={`${ this.state.selected.wiki.includes("https") ? this.state.selected.wiki:`https://en.wikipedia.org/wiki/${this.state.selected.wiki}` }`}
                                    target="blank"
                                >
                                    {this.state.infoTitle}
                                </a>
                                <button
                                    className="close-info"
                                    onClick={(e) => this.setState({ isInfoOpen: false })}
                                />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: this.state.info }} />

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(({ locale }) => ({ locale }))(Cars);
