import createStore from 'react-waterfall';

const config = {
    initialState: {
        locale: 'en_US',
    },
    actionsCreators: {
        setLocale: (previousState, actions, value) => ({
            locale: value,
        }),
    },
};

export const { Provider, connect, actions } = createStore(config);

/* babel polyfill hints (removed by minifier) */
/* eslint-disable no-unused-expressions */
Promise;
