import React from 'react';
import { connect } from '../../Store';
import './search.scss';
import enhanceWithClickOutside from 'react-click-outside';


class Search extends React.Component {

    countries = {
        'de': 'Germany',
        'fr': 'France',
        'gdr': 'German Democratic Republic',
        'hr': 'Croatia',
        'it': 'Italy',
        'jp': 'Japan',
        'kr': 'South Korea',
        'se': 'Sweden',
        'uk': 'United Kingdom',
        'us': 'United States of America',
    }

    state = {
        brands: [],
        search: '',
        showDropdown: false,
        brandResults: {},
        carResults: {},
    }

    constructor(props) {
        super();
        this.search = this.search.bind(this);
        this.updateSearchField = this.updateSearchField.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.brands !== state.brands) {
            return {brands: props.brands};
        }
        return null;
    }

    search(event) {
        let search = event.target.value;
        let data = {};
        const brandResults = {};
        const carResults = {};

        this.state.brands.forEach(brand => {
            const brandKeys = Object.keys(brand);
            brandKeys.forEach(brandKey => {
                const value = brand[brandKey];
                if (typeof brand[brandKey] === 'string' && (value.toLowerCase().includes(search.toLowerCase()))) {
                    let results = brandResults[brandKey];
                    if (!results)
                        brandResults[brandKey] = [value];
                    else {
                        if (!brandResults[brandKey].includes(value))
                            brandResults[brandKey] = [...results, value];
                    }
                }
                if (brandKey === 'country') {
                    const countryValue = this.countries[brand[brandKey]];
                    if (typeof brand[brandKey] === 'string' && (countryValue.toLowerCase().includes(search.toLowerCase()))) {
                        let results = brandResults[brandKey];
                        if (!results)
                            brandResults[brandKey] = [value];
                        else {
                            if (!brandResults[brandKey].includes(value))
                                brandResults[brandKey] = [...results, value];
                        }
                    }
                }
            });
            brand.cars.forEach(car => {
                const carKeys = Object.keys(car);
                carKeys.forEach(carKey => {
                    const value = car[carKey];
                    if (typeof value === 'string' && value.toLowerCase().includes(search.toLowerCase())  && carKey !== 'wiki') {
                        let results = carResults[carKey];
                        if (!results)
                            carResults[carKey] = [value];
                        else {
                            if (!carResults[carKey].includes(value))
                                carResults[carKey] = [...results, value];
                        }
                    }
                });
            });
        });

        console.log(search);
        console.log(brandResults);
        console.log(carResults);

        this.setState({search, brandResults, carResults});
        this.props.onSearch(event, data);
    }

    updateSearchField(term) {
        this.setState({search: term, showDropdown: false});
        this.search({target:{value: term}});
    }

    handleClickOutside() {
        this.setState({showDropdown:false})
    }

    render() {
        const { brandResults, carResults } = this.state;
        return (
            <div className="search-wrapper">
                <input
                    className="search-field"
                    type="text" value={this.state.search}
                    onClick={(e)=>this.setState({showDropdown:true})}
                    onKeyDown={(e)=>(e.keyCode===27 || e.keyCode===13) && this.setState({showDropdown:false})}
                    onChange={this.search}
                    onFocus={(event) => {this.search(event); this.setState({showDropdown: true})}}
                    />
                <span className="search-count">{this.props.filteredCount} results</span>
                <div className={`search-dropdown ${this.state.showDropdown ? 'show-dropdown' : ''}`}>
                    <ul>
                        { Object.keys(brandResults).length===0 && Object.keys(carResults).length === 0 &&
                            <li><span className="key">No results</span></li>
                        }
                        { Object.keys(brandResults).map(key => <li key={key}><span className="key">brand {key} </span><div>{brandResults[key].map(value => <span key={value} onClick={(e)=>this.updateSearchField(e.target.innerText)}>{key === 'country' ? this.countries[value] : value}</span>)}</div></li>) }
                        { Object.keys(carResults).map(key => <li key={key}><span className="key">model {key} </span><div>{carResults[key].map(value => <span key={value} onClick={(e)=>this.updateSearchField(e.target.innerText)}>{value}</span>)}</div></li>) }
                    </ul>
                </div>
            </div>
        );
    }
}

export default connect(({ locale }) => ({ locale }))(enhanceWithClickOutside(Search));
