import React from 'react';
import './App.css';
import { Provider } from './Store';
import Cars from './components/cars/Cars';

function App() {
  return (
    <Provider>
        <div className="App">
            <Cars />
        </div>
    </Provider>
  );
}

export default App;
